import axios from "axios";
import React, { useEffect, useState } from "react";
const playlistURL = "https://nopalapi.mensajito.mx/playlist";
const restreamURL = "https://nopalapi.mensajito.mx/restream";
const postPlaylistURL = "https://nopalapi.mensajito.mx/playlisttoplay";
const postRestreamURL = "https://nopalapi.mensajito.mx/restreamtoplay";
const libreURL = "https://nopalapi.mensajito.mx/libretime";
const stopURL = "https://nopalapi.mensajito.mx/stop";

const Principal = () => {
  const [playlists, setPlaylists] = useState([]);
  const [restreams, setRestreams] = useState([]);

  useEffect(() => {
    axios.get(playlistURL).then((response) => {
      setPlaylists(response.data);
    });
    axios.get(restreamURL).then((response) => {
      setRestreams(response.data);
    });
  }, []);

  const playlistToPlay = (id) => {
    console.log(id);
    const data = {
      id: id,
    };
    console.log(data);
    axios.post(postPlaylistURL, data).then((response) => {
      console.log(response);
    });
  };

  const restreamToPlay = (id) => {
    console.log(id);
    const data = {
      id: id,
    };
    console.log(data);
    axios.post(postRestreamURL, data).then((response) => {
      console.log(response);
    });
  };

  const libre = () => {
    console.log("libretime!!!");
    axios.post(libreURL).then((response) => {
      console.log(response);
    });
  }

  const stopAll = () => {
    console.log("PARAR!!!");
    axios.post(stopURL).then((response) => {
      console.log(response);
    });
  };

  return (
    <>
      <div className="contenidoAdmin">
        <div className="stop-audio-list">
          <h1>Listas de Reproducción</h1>
        </div>
        <div className="playlist-container">
          <h2 className="subtitulo">Playlists</h2>
          <div className="playlist-element">
            {playlists.map((playlist) => {
              return (
                <button
                  onClick={() => playlistToPlay(playlist.id)}
                  key={playlist.id}
                  className={`buttonBlock
                                        ${playlist.id % 4 === 0 ? "btn-A" : ""}
                                        ${playlist.id % 4 === 1 ? "btn-B" : ""}
                                        ${playlist.id % 4 === 2 ? "btn-C" : ""}
                                        ${playlist.id % 4 === 3 ? "btn-D" : ""}
                                        `}
                >
                  {playlist.nombre}
                </button>
              );
            })}
          </div>
        </div>
        <div className="playlist-container">
          <h2 className="subtitulo">Re-streams</h2>
          <div className="playlist-element">
            {restreams.map((restream) => {
              return (
                <button
                  onClick={() => restreamToPlay(restream.id)}
                  key={restream.id}
                  className={`buttonBlock
                                        ${restream.id % 4 === 0 ? "btn-A" : ""}
                                        ${restream.id % 4 === 1 ? "btn-B" : ""}
                                        ${restream.id % 4 === 2 ? "btn-C" : ""}
                                        ${restream.id % 4 === 3 ? "btn-D" : ""}
                                        `}
                >
                  {restream.nombre}
                </button>
              );
            })}
          </div>
        </div>
        <div className="playlist-container">
          <h2 className="subtitulo">Libretime</h2>
          <div className="playlist-element">
            <button onClick={libre} className="buttonBlock btn-B">
              Reiniciar
            </button>
          </div>
        </div>
        <div className="playlist-container">
          <h2 className="subtitulo">Parar</h2>
          <div className="playlist-element">
            <button onClick={stopAll} className="buttonBlock btn-R">
              Parar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Principal;
