import React from 'react'
import Principal from '../Principal/Principal'

const Main = () => {
    return (
        <>
            <header id="header_principal">
                <h1 className="m-0">Radio Nopal</h1>
                <h1 className="m-0">Sistema de Radio 24 Horas</h1>
            </header>
            <Principal />
        </>
    )
}

export default Main